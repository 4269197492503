<template>
  <span class="inline-flex gap-2 items-center bg-primary-contrast rounded px-2">
    <div
      class="w-1.5 h-1.5 rounded-full"
      :class="{
        'bg-well-green': eventDetails.eventType === 'online_event',
        'bg-well-red': eventDetails.eventType === 'on_demand',
        'bg-well-brown': eventDetails.eventType === 'in_person',
      }"
    ></div>
    <span class="uppercase">
      {{ eventDetails.typeTag }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import { getEventDetails } from '~/utils/eventDetails';

const props = defineProps({
  event: {
    type: Object as PropType<EventResponse>,
    required: true,
  },
});

const eventDetails = getEventDetails(props.event);
</script>
